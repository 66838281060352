import type { FormDataItem } from "types/form";
import type {
  Activity,
  ActivityDateInstance,
  AddOn,
  Ticket,
  TicketType
} from "./activity";
import type { CancellationPolicy } from "./cancellation";
import type { CoverImage } from "./cover-image";
import type { DiscountRule } from "./discount-rule";
import type { Field } from "./field";
import type { FieldData } from "./field-data";
import type { FieldOption } from "./field-option";
import type { FileAttachment } from "./file-attachment";
import type { SessionPass } from "./session-pass";
import type { Venue } from "./venue";

interface ActivityGroupTimePeriod {
  startTimeHours: number;
  startTimeMinutes: number;
  endTimeHours: number;
  endTimeMinutes: number;
}

interface AccessRestrictions {
  hideFromHomeListings: boolean;
  requirePassword: boolean;
  password: string;
}

export interface AgeRestrictionsAgeValue {
  months: number;
  years: number;
}

export enum AgeRestrictionCalculationCriteria {
  NoRestriction = "noRestriction",
  AgeOnSessionDate = "ageOnSessionDate",
  BirthDate = "birthDate"
}

export enum AgeRestrictionHandling {
  Block = "block",
  Warn = "warn"
}

export interface AgeRestrictions {
  criteria: AgeRestrictionCalculationCriteria;
  minAge?: AgeRestrictionsAgeValue;
  maxAge?: AgeRestrictionsAgeValue;
  minDob?: Date;
  maxDob?: Date;
  handling?: AgeRestrictionHandling;
}

export interface ActivityGroup {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  fieldData: FieldData[];
  activities: Activity<string>[];
  tickets?: Ticket[];
  addOns?: AddOn[];
  attachments?: FileAttachment[];
  coverImage?: CoverImage;
  discountRules?: DiscountRule[];
  fields?: Field[]; // attendee fields
  sessionPasses?: SessionPass[];
  cancellationPolicies?: CancellationPolicy[];
  nextSession?: Activity;
  timePeriods?: ActivityGroupTimePeriod[];
  restrictPaymentMethods: boolean;
  paymentMethods: string[];
  setBookingOpenDate: boolean;
  bookingOpenDate?: Date;
  setBookingClosingTime: boolean;
  bookingClosingTime?: number;
  accessRestrictions?: AccessRestrictions;
  ageRestrictions?: AgeRestrictions;
  bookingConfirmationEmailMsg?: string;
  enabled: boolean;
  exampleActivity?: boolean;
  created?: string;
  updated?: string;
  client: string;
  waitlistType?: WaitlistType;
  waitlistActiveEntriesType?: WaitlistType;
  toObject?: () => ActivityGroup;
  save?: () => Promise<void>;
}
export enum WaitlistType {
  None = "none",
  SingleSession = "singleSession",
  AllSessions = "allSessions"
}

export interface ActivityGroupFullListItem {
  _id: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  fieldData: FieldData[];
  activities: Activity<string>[];
  discountRules?: DiscountRule[];
  sessionPasses?: SessionPass[];
  restrictPaymentMethods: boolean;
  paymentMethods: string[];
  setBookingOpenDate: boolean;
  bookingOpenDate?: Date;
  setBookingClosingTime: boolean;
  bookingClosingTime?: number;
  enabled: boolean;
  created?: string;
  updated?: string;
  client: string;
  activityGroupStartEnd?: string;
  isPlaceLimitReached?: boolean;
  toObject?: () => ActivityGroup;
  save?: () => Promise<void>;
}

export interface ActivityGroupAvailabilityItem {
  ticketId: string;
  activityId: string;
  sessionTicketAvailable: boolean;
}

export interface ActivityGroupWithPastActivityIds extends ActivityGroup {
  activitiesPastIds: string[];
  isBookingNotYetOpen: boolean;
  isAccessPasswordRequired: boolean;
  availabilityInfo: ActivityGroupAvailabilityItem[];
}

export interface SalesData {
  [key: string]: number;
}

export interface SingleSessionTicketSales {
  [key: string]: SalesData;
}

export interface AddOnSalesData {
  [key: string]: SalesData;
}

export interface ActivityGroupSalesData {
  activitySales: SalesData;
  allSessionTicketSales: SalesData;
  singleSessionTicketSales: SingleSessionTicketSales;
  subscriptionTicketSales: SalesData;
  addOnSales: AddOnSalesData;
}

export interface AttendeeSalesData {
  [key: string]: boolean;
}

export interface AttendeeSingleSessionTicketSales {
  [key: string]: AttendeeSalesData;
}

export interface ActivityGroupSalesForAttendeeData {
  [activityGroupId: string]: {
    [attendeeId: string]: {
      allSessionTicketSales: AttendeeSalesData;
      singleSessionTicketSales: AttendeeSingleSessionTicketSales;
    };
  };
}

interface TimePeriodAggResult {
  _id: {
    startTimeHours: string;
    startTimeMinutes: string;
    endTimeHours: string;
    endTimeMinutes: string;
  };
}

export interface ActivityGroupAggregationResultItem extends ActivityGroup {
  fields: Field[];
  venues: Venue[];
  fieldOptionValues: FieldOption[];
  fieldOptionArrayValues: FieldOption[];
  periods: TimePeriodAggResult[];
}

export interface ActivitySalesAggregationResultItem {
  _id: {
    tickets: string;
    type: TicketType;
    activities: any[]; // ids
  };
  sales: number;
}

export type WaitlistEntriesCountAggData = {
  _id: any;
  count: number;
};

export interface AddOnSalesAggregationResultItem {
  addOn: string;
  activity: string;
  sales: number;
}

export enum RepeatOption {
  Daily = "daily",
  Weekly = "weekly"
}

export enum RepeatEndOption {
  Occurrences = "occurrences",
  Date = "date"
}

export interface BookingOpenDateInput {
  date: string | number;
  time: string | number;
}

export interface BookingClosingTimeInput {
  hours: string | number;
  minutes: string | number;
}

export interface AgeRestrictionAgeInput {
  months: string | number;
  years: string | number;
}

export interface ActivityGroupFormData extends FormDataItem {
  coverImage: any;
  attachments: FileAttachment[];
  datesInstances: ActivityDateInstance[];
  tickets: any[];
  addOns: any[];
  multiPurchaseDiscountRule: string;
  multiAttendeeDiscountRule: string;
  discountCodes: any[];
  sessionPass: string;
  restrictPaymentMethods: boolean;
  paymentMethods: string[];
  attendeeFields: string[];
  cancellationPolicy: string;
  setBookingOpenDate: boolean;
  bookingOpenDate: Date | BookingOpenDateInput;
  setBookingClosingTime: boolean;
  bookingClosingTime: number | BookingClosingTimeInput;
  hideFromHomeListings: boolean;
  requireAccessPassword: boolean;
  accessPassword: string;
  ageRestrictionCriteria: AgeRestrictionCalculationCriteria;
  ageRestrictionMinAge: AgeRestrictionAgeInput;
  ageRestrictionMaxAge: AgeRestrictionAgeInput;
  ageRestrictionMinDob: string | Date;
  ageRestrictionMaxDob: string | Date;
  ageRestrictionHandling: AgeRestrictionHandling;
  bookingConfirmationEmailMsg: string;
  enabled: boolean;
  waitlistType: WaitlistType;
}
